import * as Analytics from '@iheartradio/web.analytics';

import { useAnalytics } from './create-analytics';

export function useClick() {
  const analytics = useAnalytics();

  const onAnalyticsClick = (pageName: string, action: string) => {
    analytics.track({
      type: Analytics.eventType.enum.Click,
      data: {
        pageName,
        window: {
          location: {
            href: window.location.href,
          },
        },
        event: {
          location: `${pageName}|${action}`,
        },
      },
    });
  };

  return { onAnalyticsClick };
}
